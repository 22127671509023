.tasks-container{
    margin: 25px;
    margin-top: 10px;
    height: auto;
}

.tasks-header-eval{
    min-height: 47px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  
  }
.active-hrc{
    color:rgb(1, 100, 127);
}