.install-runbook-container{
    margin: 25px;
    margin-top: 10px;
}
.install-runbook-header{
  min-height: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}
.install-runbook-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.install-runbookCard {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 15%;
  font-weight: 700;
}
.install-runbookCard .ant-card-body {
  padding: 10px !important;
}
.install-search-container {
    /* width: 235px; */
  padding: 0px;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
gap: 10px;
}

.search-install-runbooks .ant-input{
    font-weight: 700!important;
    max-width: 205px;
}
.install-search-container .ant-input-group-wrapper{
  width: 20%!important;
}
.install-runbook-data-header {
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
}
.install-runbook-data-body {
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
}
.install-runbook-table .ant-table-thead th {
  background-color: #f9f9f9 !important;
}
.install-runbook-table{
 padding-top: 5px;
}
.install-runbook-table .ant-table-cell {
  font-size: 14px!important;
  font-family: sans-serif;
}
@media (max-width: 1000px) {

  
}
@media (max-width: 768px) {
  .install-runbook-card-container {
  justify-content: center;
  }
  .install-runbookCard {
    width: 90%;
  }
  .search-install-runbooks .ant-input{
    font-weight: 700!important;
    /* max-width: 205px; */
}
.install-search-container .ant-input-group-wrapper{
  width: 80%!important;
}
}
