/* Three dots menu container */

  
  /* Three dots icon */
  .menu-icon {
    /* width: 20px;
    height: 20px; */
    cursor: pointer;
    display: flex;
    gap: 3px;
  }
  
  .dot {
    display: block;
    width: 6px;
    height: 6px;
    background-color: #000;
    border-radius: 50%;
    margin-bottom: 4px;
  }
  .menu {
    position: absolute;
    right: 30px;
    min-width: 140px;
  }
   .sub-menu-task .ant-menu-submenu-title{
    min-width: 140px;
    text-align: left;
   }
   .tasks-process-container .ant-menu-root{
    border-bottom: 1px solid rgba(1, 1, 1, .1);

   }
  /* Highlight selected item */
  .selected {
    background-color: #eaeaea;
  }
  .ant-menu-item-only-child{
    text-align: left;
    font-weight: normal !important;
  }