.nist-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 10px;
}

.nist-header {
  min-height: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.nist-header p {
  margin: 25px;
  margin-top: 10px;
}

@media (max-width: 1000px) {
 
}

@media (max-width: 768px) {
  
  .nist-header p {
    margin: 10px;
  }


}
