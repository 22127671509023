.tasks-process-container{
   
        margin: 25px;
        margin-top: 10px;
        height: auto;
   
   
}
.tasks-process-container .move-it-btn{
      cursor: pointer;
      font-size: 12px!important;
}
.task-process-card .ant-card-head {
        border-bottom: 1px solid rgba(0, 0, 0, 0.4)
      }
.tasks-process-header{
    min-height: 47px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  
  }
  .task-card-body-proccess-header{
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
  }