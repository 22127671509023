.policy-description-container{
    margin: 25px;
    margin-top: 10px;
}
.policy-description-header{
    min-height: 47px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  
  }
  .policy-description-card {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .policy-description-card .container {
    width: 100%;
    padding: 10px;
  }
  .policy-description-card h2 {
    font-family: sans-serif;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: black;
    /* font-weight: bold; */
  }
  .policy-description-card p {
    font-family: sans-serif;

    margin-bottom: 10px;
    font-size: 14px;
  }
  .policy-description-card ul{
    font-family: sans-serif!important;
font-size: 14px!important;
    list-style: disc!important;
  }
  .policy-description-card ul li{
    margin-left: 25px;
  }
  @media(max-width:1000px){
   
  }
  @media (max-width: 768px) {
    
  }

 .runbook-doc-viewer{
  min-height: 100px !important;
  min-width: 300px !important;
  height: 100vh !important;
 } 

#msdoc-renderer{
  min-height: 100px !important;
  min-width: 300px !important;
} 