.runbook-container{
    margin: 25px;
    margin-top: 10px;
}
.runbook-header{
    min-height: 47px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.right-sider .ant-layout-sider-children {
    display: flex;

   
  }
  .right-sider {
    flex : 0 0 250px!important;
    max-width: 300px!important;
    width: 250px!important;
  }
  
  @media (max-width: 1024px) {
   
    .right-sider{
      display: none;
    }
  }
  