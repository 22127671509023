.runbook-container {
  margin: 25px;
  margin-top: 10px;
}
.runbook-header {
  min-height: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.runbookStore-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.runbookStoreCard {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 15%;
  font-weight: 700;
}
.runbookStoreCard .ant-card-body {
  padding: 10px !important;
}
.import-search-container {
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  padding: 0px;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}
.import-search-container-left-side{
  gap: 20px;
  display: flex;
}
.search-runbooks .ant-input {
  font-weight: 700 !important;
}
.runbookStore-data-header {
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
}
.runbookStore-data-body {
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
}
.runbook-table .ant-table-thead th {
  background-color: #f9f9f9 !important;
}
.runbook-table {
  padding-top: 5px;
}
.runbook-table .ant-table-cell {
  font-size: 14px !important;
  font-family: sans-serif;
}
@media (max-width: 1000px) {
  .import-search-container {
    gap: 10px;
  }
}
@media (max-width: 768px) {
  .runbookStoreCard {
    width: 90%;
  }
  .runbookStore-card-container {
    justify-content: center;
  }
  .import-search-container {
    gap: 10px;
  }
  .import-search-container-left-side{
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
  }
}
