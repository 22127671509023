.navbar-container {
  /* border: 1px solid black; */
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  height: auto;
  max-height: 95px;
  display: flex;
  justify-content: space-between;
}

.navbar-logo-image {
  margin: 7px 10px;
  height: 50px !important;
  max-height: 50px;
}

.navbar-userdata {
  display: flex;
  margin: 10px 20px 0px 0px;
  gap: 10px;
  line-height: normal;
  align-items: center;
}


.navbar-userdata:hover {
  cursor: pointer;
}


.hamburger-icon {
  display: none;
}

@media (max-width: 1000px) {
  .hamburger-icon {
    display: block;
    font-size: 18px;
    margin-top: 5px;
  }

  .navbar-logo-image {
    margin: 5px 10px;
    height: 20px;
    width: 45%;
  }
  .nav-user-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}