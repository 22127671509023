.runbook-detail-container {
  margin: 25px;
  margin-top: 10px;
  margin-right: 10px;
  width: 80%;
}
.runbook-detail-header {
  min-height: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.runbook-detail-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.runbook-detail-card {
  width: 15%;
}

@media (max-width: 1024px) {
  .runbook-detail-card {
    width: 20%;
  }
}

@media (max-width: 768px) {
  .runbook-detail-header {
    margin-bottom: 10px;
  }

  .runbook-detail-card-container {
    justify-content: center;
  }
  .runbook-detail-card {
    width: 80%;
  }
}

.procedural-step.highlight {
  background-color: rgb(248, 248, 147);
  transition: background-color 2s ease;
}
