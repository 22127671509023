
.vendor-runbook-container {
    /* margin-left: 25px; */
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 10px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.18px;
    font-family: sans-serif;
    width: 100%;
  }
  
  .vendor-runbook {
    height: auto;
    padding: 0px;
  
    border-radius: 15px;
  }
  
  .nist-card-container {
    margin: 25px 0px 25px 0px;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 40px;
  }
  
  .nist-card {
    width: 47%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .nist-card-container .ant-card-head {
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    background-color: #e1e1e1;
  }
  
  .summary-category {
    display: flex;
    text-align: center;
    width: 80px;
    height: 27px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }
  
  @media (max-width: 1000px) {
    /* .nist-card {
      width: 96%;
    }
    .nist-header p {
      margin: 10px;
    }
    .vendor-runbook-container {
      margin-left: 10px;
    } */
    .nist-card-container {
      margin: 25px 0px 25px 10px;
      gap: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .nist-card {
      width: 96%;
    }
  
    .nist-header p {
      margin: 10px;
    }
  
    .vendor-runbook-container {
      margin-left: 10px;
    }
  
    .nist-card-container {
      margin: 25px 0px 25px 10px;
    }
  }
  