.cves-container{
    margin: 25px;
    margin-top: 10px;
}
.cves-container .ant-select-dropdown{
    width: 325px!important;
    text-align: left;
}

.custom-select .ant-select-selector:hover {
    /* color: white !important;
    border-color: #2ebce3 !important; */
}
.custom-select .ant-select-selector{
    border-color: #d9d9d9!important;
}


.cves-header{
    min-height: 47px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.cves-filters-button-category{
    display: flex;
    gap: 20px;
    padding : 10px;
}

.cves-category-filters-button-category{
    display: flex;
  justify-content: space-between;
    padding : 10px;
}
.cve-title{
    margin-top: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}
.cve-description{
    margin-top: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}
.cves-description-link{
    width: 90%;
    text-align: left;
}
.cves-description-medium{
    width: 10%;
    text-align: right;
}
@media (max-width: 1000px) {

  
}
@media (max-width: 768px) {
    
    .btn-cves-category{
        margin-top: 10px;
    }
}
