.right-sider-container {
  display: flex;
  flex-direction: column !important;
  gap: 15px;
  width: 100%;
  padding-bottom: 10px;
}
.outline-container:hover {
  background-color: lightblue;
}

.right-sider-container .active {
  border-left: 5px solid #027ea0;
  border-bottom: none;
  /* color: #027ea0; */
  /* font-weight: none; */
}
.right-sider-container .subactive {
  border-bottom: none;
  background-color: #def0f5;
}
.right-sider-container .subsubactive {
  border-bottom: none;
  border-left: 3px solid #027ea0;
  background-color: #ebf0f1;
}

.right-sider-delete-btn {
 
  width: 50%;
  margin: auto;
}
.right-sider-outline-parent {
  width: 100%;
  text-align: left;
  overflow: hidden;
  /* overflow-x: auto; */
}
.right-sider-outline-parent:hover {
  overflow-y: auto;
}
.right-sider-outline-parent .ant-collapse-content-box {
  padding: 0px 0px !important;
}

/* .ant-collapse-content  div{
  padding-left: 18px!important;
} */
.ant-collapse-content .ant-row p{
  font-weight: normal!important;
}