.sider-parent-div {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* gap: 15px; */
  height: auto;
  
}
.sider-child-div {
  display: flex;
  height: 62px;
  gap: 10px;
  padding: 0px 8px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 3.754px;
  font-size: 15.015px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.523px;
  letter-spacing: 0.15px;
  /* justify-content: center; */
}
.sider-child-div:hover {
  cursor: pointer;
}
