.policy-category-container{
    margin: 25px;
    margin-top: 10px;
}
.policy-category-header{
    min-height: 47px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  
  }
  .policy-category-card {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  @media(max-width:1000px){
   
  }
  @media (max-width: 768px) {
    
  }