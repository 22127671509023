.governance-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.governance-header {
  min-height: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.governance-header p {
  margin: 25px;
  margin-top: 10px;
}

@media (max-width: 1000px) {}

@media (max-width: 768px) {

  .governance-header p {
    margin: 10px;
  }


}

.modal-audit-gov .ant-form-item-label label {
  width: 100px;
}

.ant-timeline-item-content p {
  margin-top: 7px;
  font-size: 14px;
  font-family: sans-serif;
}

.ant-timeline-item-content a {
  text-decoration: underline;
  color: blue;
}

.ant-tabs {
  width: 100%;
  text-align: left;
}

.governance-container .ant-empty-image svg {
  margin: 0 !important;
}

.governance-container .ant-empty-description {
  text-align: left!important;
  padding-left: 48px!important;
}