.bg-primary {
  background-color: white;
}
.bg-navbar {
  background-color: white;
}
.bg-secondary {
  background-color: white !important;
}
.font-primary {
  color: #027ea0;
}
.font-secondary {
  color: #001529;
}
.font-default {
  color: #000;
  fill: #000;
}
.font-tertiary {
  color: white;
}
.btn-primary {
  background-color: #027ea0;
  color: white;
}
.ant-btn-primary {
  background-color: #027ea0;
  color: white;
}
.btn-secondary {
  background-color: blue;
}
.border-primary {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.sider-primary {
  background-color: white !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}
.active {
  border-bottom: 3px solid #027ea0;
  /* color: #027ea0; */
  background-color: #def0f5;
  font-weight: 600;
}
.sider-parent-div .active {
  border-left: 5px solid #027ea0;
  border-bottom: none;
  color: #027ea0;
  background-color: #def0f5;
  font-weight: 600;
}
.active-icons {
  
  fill: #027ea0;
}

.sider-child-primary:hover {
  background-color: #def0f5;
}
.category-options-Identify {
  background-color: #459BD3;
  border: 1px solid #459BD3;
  color: #fff;
}
.category-options-Protect {
  background-color: #784A89;
  border: 1px solid #784A89;
  color: #fff;
}
.category-options-Detect {
  background-color: #F89D1C;
  border: 1px solid #F89D1C;
  color: #fff;
}
.category-options-Respond {
  background-color: #EA3B42;
  border: 1px solid #EA3B42;
  color: #fff;
}
.category-options-Recover {
  background-color: #37B34A;
  border: 1px solid #37B34A;
  color: #fff;
}
