.vendor-container{
    margin: 25px;
    margin-top: 10px;
}
.vendor-header{
  min-height: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
.vendorHeader-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.vendorHeaderCard {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 12%;
  font-weight: 700;
}
.vendorHeaderCard .ant-card-body {
  padding: 10px !important;
}
.vendors-card-container{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.vendorsCard{
    width: 24%;
    
}
@media (max-width: 1534px) and (min-width: 768px) {
  .vendorsCard {
    width: 45%;
  }

  .vendors-card-container {
    justify-content: left;
    column-gap: 50px;
    row-gap: 30px;
  }

}
  @media (max-width: 768px) {
    .vendorHeader-card-container {
       
    justify-content: center;
    }
    .vendorHeaderCard {
        
        width: 90%;
        
      }
    .vendorsCard{
        width: 90%;
    }
    .vendors-card-container{
    justify-content: center;
       
    }
    
  }