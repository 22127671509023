@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: sans-serif !important;
}

.ant-btn-primary {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.ant-btn:hover {
  color: white !important;
  background-color: #2ebce3 !important;
}

.ant-btn-dangerous:hover {
  color: white !important;
  background-color: rgb(229, 149, 149) !important;
}

thead[class*="ant-table-thead"] th.ant-table-cell {
  background-color: #e1e1e1 !important;
}

thead[class*="ant-table-thead"] td {
  background-color: #e1e1e1 !important;
}

.lh-1 {
  line-height: 1;
}

.ant-divider {
  border: none;
  border-bottom: 1px solid black;
}

.ant-checkbox-wrapper > span {
  color: black !important;
}

.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.7) !important;
}
.ant-select-selection-placeholder{
 color:  rgba(0, 0, 0, 0.7)!important;
}