.content {
  text-align: center;
  padding-left: 10px;
}
.sider {
  text-align: center;
  height: auto;
}
ol {
  list-style: num;
  
}

@media (max-width: 1000px) {
  .siderVisible {
    display: block;
    max-width: 100%!important;
    width: 100%!important;
    min-width: 100%!important;
  }
  .notSiderVisible {
    display: none;
  }

}
