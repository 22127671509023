.tasks-container{
    margin: 25px;
    margin-top: 10px;
    height: auto;
}

.tasks-header{
    min-height: 47px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  
  }
  .tasks-search-and-filter-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin:10px 0px 25px 0px ;
  }
  .tasks-search-and-filter-container .ant-input-group-wrapper{
    width:18%!important;
  }
  .search-tasks .ant-input {
    font-weight: 700 !important;
    /* width: 205px; */
  }
.task-parent-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:10px;
    min-height: 85vh;
    height: auto;
}
.task-child-container{
    background-color: #F5F5F5;
    width: 24%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: auto;

}
.task-child-header{
    background-color: #F5F5F5;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.task-card-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
    width: 100%;
}
.task-card{
    margin: 0px 5px;
    cursor: pointer;
    height: 7rem;
    position: relative;
}

.task-card .ant-card-body{
    padding:20px 18px 4px!important;
}
.task-user-icon{
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 2px;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}
.task-date{
    position: absolute;
    bottom: 10px;
    left: 18px;
    display: flex;
    gap: 2px;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.text-container{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.task-card-body{
    display: flex;
    flex-direction: column;
    text-align: left;
}
@media (max-width: 1024px) {
    .task-parent-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        gap:10px;
        min-height: 90vh;
        height: auto;
    }
  }
  
  @media (max-width: 768px) {
    .task-parent-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap:30px;
        height: auto;
    }
    .task-child-container{
        background-color: #F5F5F5;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        height: auto;
        padding: 10px;
    
    }
    .tasks-search-and-filter-container .ant-input-group-wrapper{
        width:40%!important;
      }
  }
.date-con label{
  width: 200px;
}